/**
 * @ Author: Your name
 * @ Create Time: 2023-07-10 09:50:49
 * @ Modified by: Your name
 * @ Modified time: 2025-02-25 11:08:09
 * @ Description:
 */

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
}

.flex-justify-content {
  display: flex;
  justify-content: space-between;
}


.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cursor {
  cursor: pointer;
}

.ant-table-header {
  table {
    visibility: visible !important;
  }
}